
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: 'Message',
    },
    placeholder: {
      type: String,
      default: 'Message',
    },
  },
  setup(_, { emit }) {
    const text = ref('')
    const store = useStore()
    const currentTab = computed(() => store.state.currentTab)
    const textInputBox = computed(() => {
      const type = currentTab.value
      return store.state.config.components.general.tabs[type].messageBox
    })
    watch(text, () => {
      emit('update:text', text.value)
    })
    return {
      text,
      textInputBox,
    }
  },
})
