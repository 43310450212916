
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Result from './HashBoxComponents/Result.vue'
import Hash from './CryptoOption/Hash.vue'
import Hmac from './CryptoOption/Hmac.vue'
import PBKDF2 from './CryptoOption/PBKDF2.vue'
import Ciphers from './CryptoOption/Ciphers.vue'
import Encoders from './CryptoOption/Encoders.vue'

export default defineComponent({
  components: {
    Result,
    Hash,
    Hmac,
    PBKDF2,
    Ciphers,
    Encoders,
  },
  setup() {
    const store = useStore()
    const cryptoType = computed(() => store.getters.cryptoType)
    const cryptoName = computed(() => store.state.currentCryptoName)
    const result = ref()

    watch(cryptoName, () => {
      result.value = undefined
    })
    const updateResult = (res: any) => {
      result.value = res
    }
    return {
      cryptoType,
      cryptoName,
      result,
      updateResult,
    }
  },
})
