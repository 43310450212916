
import { defineComponent } from 'vue'
import HashBox from '@/components/HashBox/HashBox.vue'
import RecentResult from '@/components/RecentResult/RecentResult.vue'

export default defineComponent({
  components: {
    HashBox,
    RecentResult,
  },
})
