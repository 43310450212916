<template>
  <div class="field is-grouped is-grouped-centered mt-5">
    <div class="control">
      <button
        :class="`button is-${submitButtonConfig.color}`"
        :disabled="isSubmitting"
        @click="emit('submit')"
      >
        {{ submitButtonConfig.title }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(props, { emit }) {
    const store = useStore()
    const currentTabActive = computed(() => store.state.currentTab)
    const submitButtonConfig = computed(
      () =>
        store.state.config.components.general.tabs[currentTabActive.value]
          .buttons.submit
    )
    const isSubmitting = computed(() => store.state.isSubmitting)
    return { isSubmitting, emit, submitButtonConfig }
  },
})
</script>
