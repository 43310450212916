<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">{{ cryptoName }}</p>
    </header>
    <div class="card-content">
      <div class="tabs is-centered is-large is-fullwidth">
        <ul>
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            :class="tab.active ? 'is-active' : ''"
            @click="activeTab(index)"
          >
            <a>{{ tab.title }}</a>
          </li>
        </ul>
      </div>
      <div class="tabs-content box">
        <Encrypt v-if="isEncryptTabIsActive" />
        <Decrypt v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import Encrypt from './Encrypt.vue'
import Decrypt from './Decrypt.vue'

export default defineComponent({
  components: {
    Encrypt,
    Decrypt,
  },
  setup() {
    const store = useStore()
    const cryptoName = computed(() => store.state.currentCryptoName)
    const tabs = ref([
      { title: 'Encrypt', active: true },
      { title: 'Decrypt', active: false },
    ])
    const isEncryptTabIsActive = computed(() => {
      return tabs.value[0].active
    })
    const activeTab = (tabIndex: number) => {
      tabs.value = tabs.value.map((tab, index) => {
        const active = tabIndex === index
        if (tabIndex === 0) {
          store.commit('SET_CURRENT_TAB', 'encrypt')
        } else {
          store.commit('SET_CURRENT_TAB', 'decrypt')
        }
        return { ...tab, active }
      })
    }
    return {
      tabs,
      cryptoName,
      isEncryptTabIsActive,
      activeTab,
    }
  },
})
</script>
