
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import Encrypt from './Encrypt.vue'
import Decrypt from './Decrypt.vue'

export default defineComponent({
  components: {
    Encrypt,
    Decrypt,
  },
  setup() {
    const store = useStore()
    const cryptoName = computed(() => store.state.currentCryptoName)
    const tabs = ref([
      { title: 'Encrypt', active: true },
      { title: 'Decrypt', active: false },
    ])
    const isEncryptTabIsActive = computed(() => {
      return tabs.value[0].active
    })
    const activeTab = (tabIndex: number) => {
      tabs.value = tabs.value.map((tab, index) => {
        const active = tabIndex === index
        if (tabIndex === 0) {
          store.commit('SET_CURRENT_TAB', 'encrypt')
        } else {
          store.commit('SET_CURRENT_TAB', 'decrypt')
        }
        return { ...tab, active }
      })
    }
    return {
      tabs,
      cryptoName,
      isEncryptTabIsActive,
      activeTab,
    }
  },
})
