<template>
  <div>
    <TextInputBox @update:text="updateText" />
    <div class="field" v-if="mode === 'encrypt'">
      <label class="label">{{ secretBox.label }}</label>
      <div class="control">
        <input
          class="input"
          type="text"
          :placeholder="secretBox.placeholder"
          v-model="secret"
        />
      </div>
    </div>
    <SubmitButton @submit="submit" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import CryptoService from '@/services/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { HMAC } from '@/types/Crypto'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    cryptoName: {
      type: String,
      required: true,
    },
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const secret = ref('')
    const result = ref()
    const updateText = (t: string) => (text.value = t)
    const store = useStore()
    const secretBox = computed(
      () => store.state.config.components.crypto.hmac.secretBox
    )
    const submit = async () => {
      if (props.mode === 'encrypt') {
        result.value = CryptoService.hmac(
          text.value,
          secret.value,
          props.cryptoName as HMAC
        )
      } else {
        result.value = await CryptoService.decrypt(
          text.value,
          { secret: secret.value },
          'Hmac',
          props.cryptoName
        )
      }
      emit('update:result', result.value)
    }
    return {
      text,
      secret,
      secretBox,
      updateText,
      submit,
    }
  },
})
</script>
