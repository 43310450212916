import CryptoJs from 'crypto-js'
import StoreService from './Store'
import { isEncryptAlgorithms, isEncoderAlgorithm } from './logic/utils'
import { decryptCiphers, decryptEncoders } from './logic/decrypt'
import { Hash, HMAC, Cipher, Encoder, Mode, Padding } from '@/types/Crypto'
import store from '@/store/index'

class Crypto {
  hash(text: string, type: Hash, options?: any) {
    let result: any
    if (type === 'SHA3') {
      result = CryptoJs.SHA3(text, options)
    } else {
      result = CryptoJs[type](text)
      options = {}
    }
    result = result.toString()
    this.addCryptoRecordToDatabase(text, result, 'Hash', type, options)
    return result
  }

  hmac(text: string, secret: string, type: HMAC) {
    const result = CryptoJs[type](text, secret).toString()
    const options = {
      secret,
    }
    this.addCryptoRecordToDatabase(text, result, 'Hmac', type, options)
    return result
  }

  pbkdf2(text: string, salt: string, keySize: number, iterations: number) {
    const options = {
      salt,
      keySize: keySize / 32,
      iterations,
    }
    const result = CryptoJs.PBKDF2(text, salt, {
      keySize: options.keySize,
      iterations: options.iterations,
    }).toString()
    this.addCryptoRecordToDatabase(text, result, 'PBKDF2', 'PBKDF2', options)
    return result
  }

  cipher(text: string, secret: string, type: Cipher, options: any) {
    const mode = options.mode as Mode
    const padding = options.padding as Padding
    const opts = {
      mode,
      padding,
      secret,
    }
    const result = CryptoJs[type]
      .encrypt(text, secret, {
        mode: CryptoJs.mode[mode],
        padding: CryptoJs.pad[padding],
      })
      .toString()
    this.addCryptoRecordToDatabase(text, result, 'Ciphers', type, opts)
    return result
  }

  encode(text: string, type: Encoder) {
    const words = CryptoJs.enc.Utf8.parse(text)
    const result = CryptoJs.enc[type].stringify(words)
    StoreService.addCryptoRecord(text, result, 'Encoders', type)
    return result
  }

  async decrypt(
    encryptedText: string,
    options: any,
    cryptoType: string,
    cryptoName: string
  ) {
    const done = () => store.commit('UPDATE_IS_SUBMITTING', false)
    store.commit('UPDATE_IS_SUBMITTING', true)
    if (isEncryptAlgorithms(cryptoType)) {
      StoreService.addLog(cryptoName, 'decrypt', true)
      if (isEncoderAlgorithm(cryptoType)) {
        done()
        return decryptEncoders(encryptedText, cryptoName as Encoder)
      } else {
        const { secret } = options
        delete options.secret
        const cipherOptions = options
        done()
        return decryptCiphers(
          encryptedText,
          secret,
          cipherOptions,
          cryptoName as Cipher
        )
      }
    } else {
      const records = await StoreService.getCryptoRecord(
        encryptedText,
        cryptoType,
        cryptoName
      )
      done()
      if (records.val()) {
        const result = records.val()
        return result[Object.keys(result)[0]].text
      } else {
        return null
      }
    }
  }

  addCryptoRecordToDatabase(
    text: string,
    result: string,
    cryptoType: string,
    cryptoName: string,
    options: any
  ) {
    StoreService.addCryptoRecord(text, result, cryptoType, cryptoName, options)
  }
}

export default new Crypto()
