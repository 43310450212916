<template>
  <div>
    <TextInputBox @update:text="updateText" />
    <div class="field">
      <label class="label">{{ ciphersBox.secretBox.label }}</label>
      <div class="control">
        <input
          class="input"
          type="text"
          :placeholder="ciphersBox.secretBox.placeholder"
          v-model="secret"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ ciphersBox.modesBox.label }}</label>
          <div class="control">
            <div class="select">
              <select v-model="cipherOptions.mode">
                <option value="CBC">CBC</option>
                <option value="CFB">CFB</option>
                <option value="CTR">CTR</option>
                <option value="OFB">OFB</option>
                <option value="ECB">ECB</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">{{ ciphersBox.paddingBox.label }}</label>
          <div class="control">
            <div class="select">
              <select v-model="cipherOptions.padding">
                <option value="Pkcs7">Pkcs7</option>
                <option value="Iso97971">Iso97971</option>
                <option value="AnsiX923">AnsiX923</option>
                <option value="Iso10126">Iso10126</option>
                <option value="ZeroPadding">ZeroPadding</option>
                <option value="NoPadding">NoPadding</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SubmitButton @submit="submit" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import CryptoService from '@/services/Crypto'
import { Cipher } from '@/types/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { useStore } from 'vuex'

interface cipherOptions {
  mode: string
  padding: string
}

export default defineComponent({
  props: {
    cryptoName: String,
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const secret = ref('')
    const result = ref()
    const cipherOptions = ref<cipherOptions>({
      mode: 'CBC',
      padding: 'Pkcs7',
    })
    const store = useStore()
    const ciphersBox = computed(
      () => store.state.config.components.crypto.ciphers
    )
    const updateText = (t: string) => (text.value = t)

    const submit = async () => {
      if (props.mode !== 'decrypt') {
        result.value = CryptoService.cipher(
          text.value,
          secret.value,
          props.cryptoName as Cipher,
          cipherOptions.value
        )
      } else {
        result.value = await CryptoService.decrypt(
          text.value,
          { secret: secret.value, ...cipherOptions.value },
          'Ciphers',
          props.cryptoName as Cipher
        )
      }
      emit('update:result', result.value)
    }

    return {
      text,
      secret,
      cipherOptions,
      ciphersBox,
      submit,
      updateText,
    }
  },
})
</script>
