<template>
  <div>
    <TextInputBox @update:text="updateText" />
    <div v-if="mode !== 'decrypt'">
      <div class="field">
        <label class="label">{{ pbkdf2Box.saltBox.label }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            :placeholder="pbkdf2Box.saltBox.placeholder"
            v-model="pbkdf2Options.salt"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ pbkdf2Box.iterationsBox.label }}</label>
        <div class="control">
          <input
            class="input"
            type="number"
            :placeholder="pbkdf2Box.iterationsBox.placeholder"
            v-model="pbkdf2Options.iterations"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ pbkdf2Box.keysizeBox.label }}</label>
        <div class="control">
          <div class="select">
            <select name="length" v-model="pbkdf2Options.keySize">
              <option value="128">128 bits</option>
              <option value="256">256 bits</option>
              <option value="512">512 bits</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <SubmitButton @submit="submit" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed } from 'vue'
import CryptoService from '@/services/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { useStore } from 'vuex'

interface pbkdf2Options extends Ref {
  salt: string
  iterations: string
  keySize: string
}

export default defineComponent({
  props: {
    cryptoName: String,
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const result = ref()
    const pbkdf2Options = ref({
      salt: '',
      iterations: '10',
      keySize: '128',
    }) as pbkdf2Options
    const updateText = (t: string) => (text.value = t)
    const store = useStore()
    const pbkdf2Box = computed(
      () => store.state.config.components.crypto.pbkdf2
    )
    const submit = async () => {
      if (props.mode !== 'decrypt') {
        const { salt, keySize, iterations } = pbkdf2Options.value
        result.value = CryptoService.pbkdf2(
          text.value,
          salt,
          parseInt(keySize),
          parseInt(iterations)
        )
      } else {
        result.value = await CryptoService.decrypt(
          text.value,
          pbkdf2Options,
          'PBKDF2',
          'PBKDF2'
        )
      }
      emit('update:result', result.value)
    }

    return {
      text,
      pbkdf2Options,
      pbkdf2Box,
      submit,
      updateText,
    }
  },
})
</script>
