<template>
  <div v-if="result !== undefined">
    <template v-if="result !== null">
      <div class="field">
        <label class="label">{{ resultBox.label }}</label>
        <div class="control">
          <textarea
            class="textarea"
            :placeholder="resultBox.placeholder"
            :value="result"
          ></textarea>
        </div>
      </div>
      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button :class="`button is-${copyButton.color}`" @click="copy">
            {{ isCopied ? copyButton.copiedTitle : copyButton.title }}
          </button>
        </div>
      </div>
    </template>
    <h1 class="is-size-4 mt-5 has-text-centered" v-else>
      {{ resultBox.noResult }}
    </h1>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    result: String,
    mode: String,
  },
  setup(props) {
    const timeDisplay = 2
    const isCopied = ref(false)
    const displayCopied = () => {
      setTimeout(() => {
        isCopied.value = false
      }, timeDisplay * 1000)
    }
    const store = useStore()
    const currentTab = computed(() => store.state.currentTab)
    const encryptionData = computed(
      () => store.state.config.components.general.tabs.encrypt
    )
    const decryptionData = computed(
      () => store.state.config.components.general.tabs.decrypt
    )

    const resultBox = computed(() => {
      return currentTab.value === 'encrypt'
        ? encryptionData.value.result
        : decryptionData.value.result
    })
    const copyButton = computed(() => {
      return currentTab.value === 'encrypt'
        ? encryptionData.value.buttons.copy
        : decryptionData.value.buttons.copy
    })
    const copy = () => {
      isCopied.value = true
      displayCopied()
      const data = props.result as string
      let input = document.createElement('input')
      input.value = data
      input.id = 'inp'
      document.body.appendChild(input)
      let text = document.getElementById('inp') as HTMLInputElement
      text.select()
      document.execCommand('copy')
      text.remove()
    }

    return {
      copy,
      resultBox,
      copyButton,
      isCopied,
    }
  },
})
</script>
