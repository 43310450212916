<template>
  <div>
    <Hash
      v-if="cryptoType === 'Hash'"
      :crypto-name="cryptoName"
      mode="decrypt"
      @update:result="updateResult"
    />
    <Hmac
      v-if="cryptoType === 'Hmac'"
      :crypto-name="cryptoName"
      mode="decrypt"
      @update:result="updateResult"
    />
    <PBKDF2
      v-if="cryptoType === 'PBKDF2'"
      :crypto-name="cryptoName"
      mode="decrypt"
      @update:result="updateResult"
    />
    <Ciphers
      v-if="cryptoType === 'Ciphers'"
      :crypto-name="cryptoName"
      mode="decrypt"
      @update:result="updateResult"
    />
    <Encoders
      v-if="cryptoType === 'Encoders'"
      :crypto-name="cryptoName"
      mode="decrypt"
      @update:result="updateResult"
    />
    <transition enter-active-class="animate__animated animate__fadeIn">
      <Result mode="decrypt" :result="result" />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Result from './HashBoxComponents/Result.vue'
import Hash from './CryptoOption/Hash.vue'
import Hmac from './CryptoOption/Hmac.vue'
import PBKDF2 from './CryptoOption/PBKDF2.vue'
import Ciphers from './CryptoOption/Ciphers.vue'
import Encoders from './CryptoOption/Encoders.vue'

export default defineComponent({
  components: {
    Result,
    Hash,
    Hmac,
    PBKDF2,
    Ciphers,
    Encoders,
  },
  setup() {
    const store = useStore()
    const cryptoType = computed(() => store.getters.cryptoType)
    const cryptoName = computed(() => store.state.currentCryptoName)
    const result = ref()

    watch(cryptoName, () => {
      result.value = undefined
    })
    const updateResult = (res: any) => {
      result.value = res
    }
    return {
      cryptoType,
      cryptoName,
      result,
      updateResult,
    }
  },
})
</script>
