
import { defineComponent, ref, Ref, computed } from 'vue'
import CryptoService from '@/services/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { useStore } from 'vuex'

interface pbkdf2Options extends Ref {
  salt: string
  iterations: string
  keySize: string
}

export default defineComponent({
  props: {
    cryptoName: String,
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const result = ref()
    const pbkdf2Options = ref({
      salt: '',
      iterations: '10',
      keySize: '128',
    }) as pbkdf2Options
    const updateText = (t: string) => (text.value = t)
    const store = useStore()
    const pbkdf2Box = computed(
      () => store.state.config.components.crypto.pbkdf2
    )
    const submit = async () => {
      if (props.mode !== 'decrypt') {
        const { salt, keySize, iterations } = pbkdf2Options.value
        result.value = CryptoService.pbkdf2(
          text.value,
          salt,
          parseInt(keySize),
          parseInt(iterations)
        )
      } else {
        result.value = await CryptoService.decrypt(
          text.value,
          pbkdf2Options,
          'PBKDF2',
          'PBKDF2'
        )
      }
      emit('update:result', result.value)
    }

    return {
      text,
      pbkdf2Options,
      pbkdf2Box,
      submit,
      updateText,
    }
  },
})
