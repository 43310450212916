import CryptoJS from 'crypto-js'
import { Encoder, Cipher, Mode, Padding } from '@/types/Crypto'

export const decryptEncoders = (encryptedText: string, cryptoName: Encoder) => {
  const words = CryptoJS.enc[cryptoName].parse(encryptedText)
  const text = CryptoJS.enc.Utf8.stringify(words)
  return text.toString()
}

export const decryptCiphers = (
  encryptedText: string,
  secret: string,
  options: any,
  cryptoName: Cipher
) => {
  const mode: Mode = options.mode
  const padding: Padding = options.padding
  const text = CryptoJS[cryptoName].decrypt(encryptedText, secret, {
    mode: CryptoJS.mode[mode],
    padding: CryptoJS.pad[padding],
  })
  return text.toString(CryptoJS.enc.Utf8)
}
