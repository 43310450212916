<template>
  <div>
    <TextInputBox @update:text="updateText" />
    <SubmitButton @submit="submit" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import CryptoService from '@/services/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { Encoder } from '@/types/Crypto'

export default defineComponent({
  props: {
    cryptoName: {
      type: String,
      required: true,
    },
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const result = ref()
    const updateText = (t: string) => (text.value = t)

    const submit = async () => {
      if (props.mode !== 'decrypt') {
        result.value = CryptoService.encode(
          text.value,
          props.cryptoName as Encoder
        )
      } else {
        try {
          result.value = await CryptoService.decrypt(
            text.value,
            null,
            'Encoders',
            props.cryptoName
          )
        } catch (e) {
          result.value = null
        }
      }
      emit('update:result', result.value)
    }

    return {
      text,
      submit,
      updateText,
    }
  },
})
</script>
