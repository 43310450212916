
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    result: String,
    mode: String,
  },
  setup(props) {
    const timeDisplay = 2
    const isCopied = ref(false)
    const displayCopied = () => {
      setTimeout(() => {
        isCopied.value = false
      }, timeDisplay * 1000)
    }
    const store = useStore()
    const currentTab = computed(() => store.state.currentTab)
    const encryptionData = computed(
      () => store.state.config.components.general.tabs.encrypt
    )
    const decryptionData = computed(
      () => store.state.config.components.general.tabs.decrypt
    )

    const resultBox = computed(() => {
      return currentTab.value === 'encrypt'
        ? encryptionData.value.result
        : decryptionData.value.result
    })
    const copyButton = computed(() => {
      return currentTab.value === 'encrypt'
        ? encryptionData.value.buttons.copy
        : decryptionData.value.buttons.copy
    })
    const copy = () => {
      isCopied.value = true
      displayCopied()
      const data = props.result as string
      let input = document.createElement('input')
      input.value = data
      input.id = 'inp'
      document.body.appendChild(input)
      let text = document.getElementById('inp') as HTMLInputElement
      text.select()
      document.execCommand('copy')
      text.remove()
    }

    return {
      copy,
      resultBox,
      copyButton,
      isCopied,
    }
  },
})
