export const isEncryptAlgorithms = (cryptoType: string) => {
  return cryptoType === 'Ciphers' || cryptoType === 'Encoders'
}

export const isEncoderAlgorithm = (cryptoType: string) => {
  return cryptoType === 'Encoders'
}

export const isCipherAlgorithm = (cryptoType: string) => {
  return cryptoType === 'Ciphers'
}
