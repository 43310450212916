
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(props, { emit }) {
    const store = useStore()
    const currentTabActive = computed(() => store.state.currentTab)
    const submitButtonConfig = computed(
      () =>
        store.state.config.components.general.tabs[currentTabActive.value]
          .buttons.submit
    )
    const isSubmitting = computed(() => store.state.isSubmitting)
    return { isSubmitting, emit, submitButtonConfig }
  },
})
