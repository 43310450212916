<template>
  <div class="card mt-5">
    <header class="card-header">
      <p class="card-header-title">{{ recentResultsText.title }}</p>
    </header>
    <div class="card-content">
      <div class="table container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th>{{ recentResultsText.columns.text.title }}</th>
              <th>{{ recentResultsText.columns.result.title }}</th>
              <th>{{ recentResultsText.columns.createdAt.title }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in records" :key="index">
              <th>{{ index + 1 }}</th>
              <td>{{ truncateTextByScreenSize(record.text) }}</td>
              <td>{{ truncateTextByScreenSize(record.result) }}</td>
              <td>{{ createdAtFromNow(record.createdAt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref } from 'vue'
import StoreService from '@/services/Store'
import { useStore } from 'vuex'
import { truncateTextByScreenSize, createdAtFromNow } from '@/utils/utils'

export default defineComponent({
  setup() {
    const store = useStore()
    const cryptoName = computed(() => store.state.currentCryptoName)
    const newCryptoEncrypted = computed(() => store.state.newCryptoEncrypted)
    const cryptoType = computed(() => store.getters.cryptoType)
    const records = ref([]) as any
    const recentResultsText = computed(
      () => store.state.config.components.general.recentTable
    )
    const getRecentRecords = async () => {
      const recentRecords = await StoreService.getRecentCryptoRecords(
        cryptoType.value,
        cryptoName.value
      )
      recentRecords.once('value', (snap) => {
        records.value = []
        const data: any[] = []
        snap.forEach((child) => {
          data.push(child.val())
        })
        records.value = data.reverse()
      })
    }

    watch(cryptoName, () => getRecentRecords())
    watch(newCryptoEncrypted, () => {
      const newRecords = newCryptoEncrypted.value
      const lastRecordResult = records.value[0] ? records.value[0].result : null
      if (newRecords && lastRecordResult !== newRecords.result) {
        records.value.unshift(newRecords)
        store.commit('SET_NEW_CRYPTO_ENCRYPTED', null)
      }
    })
    getRecentRecords()

    return {
      records,
      truncateTextByScreenSize,
      createdAtFromNow,
      recentResultsText,
    }
  },
})
</script>
