<template>
  <div>
    <TextInputBox @update:text="updateText" />
    <div class="field" v-if="cryptoName === 'SHA3' && mode === 'encrypt'">
      <label class="label"> {{ outputLengthBox.label }} </label>
      <div class="control">
        <div class="select">
          <select name="length" v-model="outputLength">
            <option value="224">224 bits</option>
            <option value="256">256 bits</option>
            <option value="384">384 bits</option>
            <option value="512">512 bits</option>
          </select>
        </div>
      </div>
    </div>
    <SubmitButton @submit="submit" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import CryptoService from '@/services/Crypto'
import TextInputBox from './components/TextInputBox.vue'
import SubmitButton from './components/SubmitButton.vue'
import { Hash } from '@/types/Crypto'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    cryptoName: {
      type: String,
      required: true,
    },
    mode: String,
  },
  components: { TextInputBox, SubmitButton },
  setup(props, { emit }) {
    const text = ref('')
    const result = ref()
    const outputLength = ref('224')
    const store = useStore()

    const outputLengthBox = computed(
      () => store.state.config.components.crypto.hash.outputLengthBox
    )
    const updateText = (t: string) => (text.value = t)

    const submit = async () => {
      if (props.mode === 'encrypt') {
        result.value = CryptoService.hash(
          text.value,
          props.cryptoName as Hash,
          {
            outputLength: parseInt(outputLength.value),
          }
        )
      } else {
        result.value = await CryptoService.decrypt(
          text.value,
          {},
          'Hash',
          props.cryptoName
        )
      }
      emit('update:result', result.value)
    }

    return {
      text,
      outputLength,
      outputLengthBox,
      submit,
      updateText,
    }
  },
})
</script>
